import { useEffect, useState, type Dispatch, type SetStateAction } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  ArrowTopRightOnSquareIcon,
  LinkIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import type {
  FetchLinkedResourceRow,
  FetchLinkedResourceRowVariables,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'

import { getContentNiceName } from 'src/components/HubDash/CardSettingsDrawer/LinkLearningContent/LinkLearningContentDialog'
import type { LinkedResourceType } from 'src/components/HubDash/HubDashLayoutListCell'
import { useAuth } from 'src/Providers'

export const LinkOut = ({ to, children }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-500 hover:underline"
    >
      {children}
      <ArrowTopRightOnSquareIcon className="h-5 w-5" />
    </a>
  )
}

export const LinkedRowList = ({
  linkedWorkflowsRows,
  noLink = false,
}: {
  linkedWorkflowsRows: LinkedResourceRow[]
  noLink?: boolean
}) => (
  <div>
    <ul>
      {linkedWorkflowsRows?.map((result) => (
        <li key={result?.id}>
          {noLink ? (
            <span>
              Layout:&nbsp;
              {result?.hubDashCard?.hubDashLayout?.name} - Card:{' '}
              {result?.hubDashCard?.name}
            </span>
          ) : (
            <LinkOut
              to={routes.hubDashWithId({
                layoutId: result?.hubDashCard?.hubDashLayout?.id,
              })}
            >
              <span>
                Layout:&nbsp;
                {result?.hubDashCard?.hubDashLayout?.name} - Card:{' '}
                {result?.hubDashCard?.name}
              </span>
            </LinkOut>
          )}
        </li>
      ))}
    </ul>
  </div>
)

export const FETCH_LINKED_RESOURCE = gql`
  query FetchLinkedResourceRow(
    $resourceId: Int!
    $resourceType: LinkedResourceType!
  ) {
    hubDashCardToHubResourcesByIdAndType(
      resourceId: $resourceId
      resourceType: $resourceType
    ) {
      id
      resourceId
      hubDashCard {
        id
        name
        hubDashLayout {
          id
          name
        }
      }
    }
  }
`

export type LinkedResourceRow =
  FetchLinkedResourceRow['hubDashCardToHubResourcesByIdAndType'][0]

interface LinkedContentBadgeProps {
  resourceId: number
  resourceType: LinkedResourceType
  returnLinkedContent: Dispatch<SetStateAction<LinkedResourceRow[]>>
}

const LinkedContentBadge = ({
  resourceId,
  resourceType,
  returnLinkedContent,
}: LinkedContentBadgeProps) => {
  const { hasRole } = useAuth()

  const roles = ['ADMIN', 'EDITOR', 'OWNER']
  const isAdmin = hasRole(roles)

  const [showBadge, setShowBadge] = useState<boolean>(false)
  const [linkedResults, setLinkedResults] = useState<LinkedResourceRow[]>([])

  const [fetchLinkedResource] = useLazyQuery<
    FetchLinkedResourceRow,
    FetchLinkedResourceRowVariables
  >(FETCH_LINKED_RESOURCE, {
    onCompleted: (data) => {
      const linkedData = data?.hubDashCardToHubResourcesByIdAndType
      if (linkedData?.length > 0) {
        // Push up the found rows
        returnLinkedContent(linkedData)

        // Update results
        setLinkedResults(linkedData)

        // Show Badge
        setShowBadge(true)
      }
    },
  })

  useEffect(() => {
    // Fetch for a linked resource row
    const checkForLinkedResource = async () => {
      await fetchLinkedResource({
        variables: {
          resourceId,
          resourceType,
        },
      })
    }

    if (resourceId && resourceType && isAdmin) {
      checkForLinkedResource()
    }
  }, [resourceId, resourceType, isAdmin])

  if (!showBadge || !isAdmin) {
    return null
  }
  return (
    <Tooltip
      title={
        <div>
          <p>{`This ${getContentNiceName(resourceType)} is Linked to the following HubDash Cards and Layouts:`}</p>
          <LinkedRowList linkedWorkflowsRows={linkedResults} noLink />
        </div>
      }
      arrow
    >
      <p className="sticky bottom-[20px] left-[20px] z-10 flex w-[175px] items-center justify-between gap-2 rounded-full border border-indigo-600 bg-indigo-100 p-1 px-2 text-sm text-indigo-600">
        <LinkIcon className="h-4 w-4" />
        <span>Linked to HubDash</span>
      </p>
    </Tooltip>
  )
}

export default LinkedContentBadge
