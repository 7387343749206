import type {
  FindMembershipsQuery,
  FindMembershipsQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import UserAutocomplete from '../UserAutocomplete/UserAutocomplete'

export const QUERY = gql`
  query FindMembershipsQuery {
    membershipsByClient(activeOnly: true) {
      id
      clientId
      user {
        id
        avatarUrl
        email
        name
        baserowUserId
      }
      membershipGroups {
        id
        membershipGroup {
          id
          name
        }
      }
    }
    membershipGroupsByClient {
      id
      name
    }
  }
`
type CellProps = CellSuccessProps<
  FindMembershipsQuery,
  FindMembershipsQueryVariables
>
export type HubDashMembership = CellProps['membershipsByClient'][0]
export type HubDashMembershipGroup = CellProps['membershipGroupsByClient'][0]

export const Loading = () => (
  <div>
    <LoadingSpinner size="small" />
  </div>
)

export const Empty = () => {
  return <UserAutocomplete memberships={[]} groups={[]} />
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ membershipsByClient, membershipGroupsByClient }) => {
  return (
    <UserAutocomplete
      memberships={membershipsByClient}
      groups={membershipGroupsByClient}
    />
  )
}
