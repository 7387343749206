import { useEffect, useState, type ChangeEvent } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import type {
  FindAllLearnerCoursesInClient,
  FindAllLearnerCoursesInClientVariables,
  FindAllInformerListCards,
  FindAllInformerListCardsVariables,
  FindReactMapsSidePanelQuery,
  FindReactMapsSidePanelQueryVariables,
  GetAllKnowledgeBaseItemsQuery,
  GetAllKnowledgeBaseItemsQueryVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import {
  KB_ITEM_TYPE,
  QUERY as KB_QUERY,
  KnowledgeBaseItemStatus,
} from 'src/components/KnowledgeCell/KnowledgeCell'
import Button from 'src/components/Library/Button/Button'
import Loading from 'src/components/Library/Loading/Loading'
import { SearchField } from 'src/components/Library/SearchField'
import { QUERY as PROCESS_MAP_QUERY } from 'src/components/ReactMap/ReactMapSidePanelCell/ReactMapSidePanelCell'

import {
  LinkedResourceType,
  type HubDashLinkedContent,
} from '../../HubDashLayoutListCell'

import LearnerCourseSelector from './LearnerCourseSelector'

const LEARNER_QUERY = gql`
  query FindAllLearnerCoursesInClient {
    learnerCategories: learnerCategoriesByClientAsAdmin(status: PUBLISHED) {
      id
      learnerCourses(status: PUBLISHED, isAdmin: true) {
        id
        name
        learnerActivities {
          id
          learnerTasks {
            id
            name
          }
        }
      }
    }
  }
`

const LINK_QUERY = gql`
  query FindAllInformerListCards {
    informerListCards {
      id
      name
      url
    }
  }
`

export type LinkedLearnerTaskDetails = {
  activityId: number
  taskId: number
  taskName: string
}
export type LinkedHomeLinkDetails = {
  url: string
}

export type LinkedContent = HubDashLinkedContent & {
  tasks?: LinkedLearnerTaskDetails[]
  link?: LinkedHomeLinkDetails
}

interface LinkLearningContentDialogProps {
  contentType: LinkedResourceType
  isOpen: boolean
  linkedProcessMaps: HubDashLinkedContent[]
  linkedKnowledgeBaseItems: HubDashLinkedContent[]
  linkedLearnerItems: HubDashLinkedContent[]
  linkedHomeLinkItems: HubDashLinkedContent[]
  handleClose: () => void
  handleSave: (
    selectedItems: HubDashLinkedContent[],
    contentType: LinkedResourceType,
  ) => void
}

const filterDisplayOptionsByType = (
  contentType: LinkedResourceType,
  processMapOptions,
  knowledgeBaseOptions,
  learnerOptions,
  homeLinkOptions,
): HubDashLinkedContent[] => {
  if (contentType === LinkedResourceType.PROCESS_MAP) {
    return processMapOptions
  } else if (contentType === LinkedResourceType.KNOWLEDGE_BASE) {
    return knowledgeBaseOptions
  } else if (contentType === LinkedResourceType.LEARNER_COURSE) {
    return learnerOptions
  } else if (contentType === LinkedResourceType.HOME_LINK) {
    return homeLinkOptions
  } else {
    return []
  }
}

const filterOptionsBySearchTerm = (
  options: HubDashLinkedContent[],
  searchTerm: string,
): HubDashLinkedContent[] => {
  return options?.filter((option) => {
    return option?.resourceName
      ?.toLowerCase()
      ?.includes(searchTerm?.toLowerCase())
  })
}

const formatProcessMapResultsToLinkedResource = (
  mapCategoriesWithMaps: FindReactMapsSidePanelQuery['mapCategoryList'],
): HubDashLinkedContent[] => {
  const results: HubDashLinkedContent[] = mapCategoriesWithMaps
    ?.flatMap((category) => {
      return category?.reactMaps?.map((map) => {
        const mapResult: HubDashLinkedContent = {
          resourceId: map?.id,
          resourceName: map?.name,
          resourceType: LinkedResourceType.PROCESS_MAP,
        }

        return mapResult
      })
    })
    ?.sort((a, b) =>
      a?.resourceName?.toLowerCase() > b?.resourceName?.toLowerCase() ? 1 : -1,
    )

  return results
}
const formatHomeLinkResultsToLinkedResource = (
  allInformerListCards: FindAllInformerListCards['informerListCards'],
): LinkedContent[] => {
  const results: LinkedContent[] = allInformerListCards
    ?.map((informerListCard) => {
      const mapResult: LinkedContent = {
        resourceId: informerListCard?.id,
        resourceName: informerListCard?.name,
        resourceType: LinkedResourceType.HOME_LINK,
        link: { url: informerListCard?.url },
      }
      return mapResult
    })
    ?.sort((a, b) =>
      a?.resourceName?.toLowerCase() > b?.resourceName?.toLowerCase() ? 1 : -1,
    )

  return results
}

const formatKBResultsToLinkedResource = (
  allKbItems: GetAllKnowledgeBaseItemsQuery['kbItems'],
): HubDashLinkedContent[] => {
  const results: HubDashLinkedContent[] = allKbItems
    ?.filter((kbItem) => {
      return ![KB_ITEM_TYPE.CATEGORY, KB_ITEM_TYPE.STORAGE_OBJECT]?.includes(
        kbItem?.type as KB_ITEM_TYPE,
      )
    })
    ?.map((kbItem) => {
      const mapResult: HubDashLinkedContent = {
        resourceId: kbItem?.id,
        resourceName: kbItem?.title,
        resourceType: LinkedResourceType.KNOWLEDGE_BASE,
      }

      return mapResult
    })
    ?.sort((a, b) =>
      a?.resourceName?.toLowerCase() > b?.resourceName?.toLowerCase() ? 1 : -1,
    )

  return results
}

const formatLearnerResultsToLinkedResource = (
  learnerCategories: FindAllLearnerCoursesInClient['learnerCategories'],
): LinkedContent[] => {
  const results: LinkedContent[] = learnerCategories
    ?.flatMap((category) => {
      return category?.learnerCourses?.map((course) => {
        // Map tasks for return
        const tasks = course?.learnerActivities?.flatMap((chapter) => {
          return chapter?.learnerTasks?.flatMap((task) => {
            return {
              activityId: chapter?.id,
              taskId: task?.id,
              taskName: task?.name,
            }
          })
        })

        const courseResult: LinkedContent = {
          resourceId: course?.id,
          resourceName: course?.name,
          resourceType: LinkedResourceType.LEARNER_COURSE,
          tasks,
        }

        return courseResult
      })
    })
    ?.sort((a, b) =>
      a?.resourceName?.toLowerCase() > b?.resourceName?.toLowerCase() ? 1 : -1,
    )

  return results
}

export const getContentNiceName = (contentType: LinkedResourceType): string => {
  switch (contentType) {
    case LinkedResourceType.PROCESS_MAP:
      return 'Process Map'
    case LinkedResourceType.KNOWLEDGE_BASE:
      return 'Knowledge Base Article'
    case LinkedResourceType.LEARNER_COURSE:
      return 'Learner Course'
    case LinkedResourceType.HOME_LINK:
      return 'Home Link'
    default:
      return ''
  }
}

const LinkLearningContentDialog = ({
  contentType,
  isOpen,
  linkedProcessMaps,
  linkedKnowledgeBaseItems,
  linkedLearnerItems,
  linkedHomeLinkItems,
  handleClose,
  handleSave,
}: LinkLearningContentDialogProps) => {
  const contentNiceName = getContentNiceName(contentType)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [displayOptions, setDisplayOptions] = useState<LinkedContent[]>([])

  const [queryLoading, setQueryLoading] = useState<boolean>(true)

  // Content Options
  const [processMapOptions, setProcessMapOptions] = useState<
    HubDashLinkedContent[]
  >([])

  const [knowledgeBaseOptions, setKnowledgeBaseOptions] = useState<
    HubDashLinkedContent[]
  >([])

  const [learnerOptions, setLearnerOptions] = useState<LinkedContent[]>([])

  const [homeLinkOptions, setHomeLinkOptions] = useState<LinkedContent[]>([])

  const [selectedItems, setSelectedItems] = useState<HubDashLinkedContent[]>([])

  const noSelectableItems = displayOptions?.length === 0

  const [getProcessMaps] = useLazyQuery<
    FindReactMapsSidePanelQuery,
    FindReactMapsSidePanelQueryVariables
  >(PROCESS_MAP_QUERY, {
    onCompleted: (data) => {
      const formattedResults = formatProcessMapResultsToLinkedResource(
        data?.mapCategoryList,
      )
      setProcessMapOptions(formattedResults)
      setQueryLoading(false)
    },
    onError: () => {
      toast.error('There was a problem fetching your Process Maps.', {
        duration: 5000,
      })
      setQueryLoading(false)
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const [getKnowledgeBaseItems] = useLazyQuery<
    GetAllKnowledgeBaseItemsQuery,
    GetAllKnowledgeBaseItemsQueryVariables
  >(KB_QUERY, {
    onCompleted: (data) => {
      const formattedResults = formatKBResultsToLinkedResource(data?.kbItems)
      setKnowledgeBaseOptions(formattedResults)
      setQueryLoading(false)
    },
    onError: () => {
      toast.error(
        'There was a problem fetching your Knowledge Base Articles.',
        {
          duration: 5000,
        },
      )
      setQueryLoading(false)
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const [getLearnerCourses] = useLazyQuery<
    FindAllLearnerCoursesInClient,
    FindAllLearnerCoursesInClientVariables
  >(LEARNER_QUERY, {
    onCompleted: (data) => {
      const formattedResults = formatLearnerResultsToLinkedResource(
        data?.learnerCategories,
      )
      setLearnerOptions(formattedResults)
      setQueryLoading(false)
    },
    onError: () => {
      toast.error('There was a problem fetching your Learner Courses.', {
        duration: 5000,
      })
      setQueryLoading(false)
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  // LINK_QUERY
  const [getHomeLinks] = useLazyQuery<
    FindAllInformerListCards,
    FindAllInformerListCardsVariables
  >(LINK_QUERY, {
    onCompleted: (data) => {
      const formattedResults = formatHomeLinkResultsToLinkedResource(
        data?.informerListCards,
      )
      setHomeLinkOptions(formattedResults)
      setQueryLoading(false)
    },
    onError: () => {
      toast.error('There was a problem fetching your home links.', {
        duration: 5000,
      })
      setQueryLoading(false)
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  // Reset all option types
  const resetOptions = () => {
    setProcessMapOptions([])
    setSelectedItems([])
    setSearchTerm('')
  }

  useEffect(() => {
    // Fetch Resource Type options
    const fetchProcessMaps = async () => {
      await getProcessMaps({
        variables: {
          status: 'PUBLISHED',
        },
      })
    }

    const fetchKnowledgeBaseItems = async () => {
      await getKnowledgeBaseItems({
        variables: {
          statuses: [KnowledgeBaseItemStatus.PUBLISHED],
          asAdmin: true,
        },
      })
    }

    const fetchLearnerCourseItems = async () => {
      await getLearnerCourses()
    }

    const fetchHomeLinkItems = async () => {
      await getHomeLinks()
    }

    if (isOpen) {
      if (contentType === LinkedResourceType.PROCESS_MAP) {
        setQueryLoading(true)
        fetchProcessMaps()
        setSelectedItems(linkedProcessMaps)
      }
      if (contentType === LinkedResourceType.KNOWLEDGE_BASE) {
        setQueryLoading(true)
        fetchKnowledgeBaseItems()
        setSelectedItems(linkedKnowledgeBaseItems)
      }
      if (contentType === LinkedResourceType.LEARNER_COURSE) {
        setQueryLoading(true)
        fetchLearnerCourseItems()
        setSelectedItems(linkedLearnerItems)
      }
      if (contentType === LinkedResourceType.HOME_LINK) {
        setQueryLoading(true)
        fetchHomeLinkItems()
        setSelectedItems(linkedHomeLinkItems)
      }
    } else {
      resetOptions()
    }
  }, [isOpen, contentType])

  useEffect(() => {
    const options = filterDisplayOptionsByType(
      contentType,
      processMapOptions,
      knowledgeBaseOptions,
      learnerOptions,
      homeLinkOptions,
    )
    if (searchTerm) {
      const optionsFilteredBySearchTerm = filterOptionsBySearchTerm(
        options,
        searchTerm,
      )

      setDisplayOptions(optionsFilteredBySearchTerm)
    } else {
      setDisplayOptions(options)
    }
  }, [
    searchTerm,
    processMapOptions,
    knowledgeBaseOptions,
    learnerOptions,
    homeLinkOptions,
  ])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{ className: 'min-w-[700px]' }}
    >
      <DialogTitle id="scroll-dialog-title">
        {` Link ${contentNiceName}s`}
      </DialogTitle>
      <DialogContent
        className="flex flex-col p-0"
        dividers
        sx={{ height: 600 }}
      >
        {queryLoading && (
          <div className="grid w-full grow place-items-center p-10">
            <div className="flex flex-col gap-2">
              <Loading />
              <p className="text-gray-400">
                {`Loading options for ${contentNiceName}s`}
              </p>
            </div>
          </div>
        )}
        {!queryLoading && (
          <div className="flex w-full flex-col gap-2 px-4">
            <div className="relative mb-4 flex w-full flex-col gap-2">
              <div className="sticky top-0 z-10 mb-1 border-b bg-white py-2">
                <div className="mb-2 flex gap-2 rounded bg-amber-100 p-2 text-sm">
                  <ExclamationTriangleIcon className="h-4 w-4 shrink-0" />
                  Please make sure your linked resources have the correct access
                  permissions so that your team members can view the content.
                </div>
                <SearchField
                  value={searchTerm}
                  onChange={setSearchTerm}
                  disabled={queryLoading}
                />
              </div>
              {displayOptions?.length > 0 && (
                <>
                  {contentType === LinkedResourceType.LEARNER_COURSE && (
                    <LearnerCourseSelector
                      options={displayOptions}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  )}
                  {contentType !== LinkedResourceType.LEARNER_COURSE && (
                    <>
                      {displayOptions?.map((option) => {
                        return (
                          <FormControlLabel
                            className="m-0 w-full rounded border hover:bg-gray-50"
                            key={option?.resourceId}
                            control={
                              <Checkbox
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (event.target.checked) {
                                    const newOption = { ...option }
                                    if (
                                      contentType ===
                                      LinkedResourceType.HOME_LINK
                                    ) {
                                      newOption.linkMetaData = {
                                        url: option?.link?.url,
                                      }
                                    }
                                    setSelectedItems((prevSelectedItems) => [
                                      ...prevSelectedItems,
                                      newOption,
                                    ])
                                  } else {
                                    setSelectedItems((prevSelectedItems) =>
                                      prevSelectedItems?.filter(
                                        (item) =>
                                          item?.resourceId !==
                                          option?.resourceId,
                                      ),
                                    )
                                  }
                                }}
                                checked={
                                  !!selectedItems?.find(
                                    (selected) =>
                                      selected?.resourceId ===
                                      option?.resourceId,
                                  )
                                }
                              />
                            }
                            label={option?.resourceName}
                          />
                        )
                      })}
                    </>
                  )}
                </>
              )}
              {displayOptions?.length === 0 && (
                <p className="w-full grow py-10 text-center text-gray-400">
                  {`No ${contentNiceName}s ${searchTerm ? 'match your search term.' : 'found.'}`}
                </p>
              )}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button fullWidth={false} variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={queryLoading || noSelectableItems}
          fullWidth={false}
          onClick={() => handleSave(selectedItems, contentType)}
        >
          Link Selected
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LinkLearningContentDialog
