export const DELETE_HUBDASH_CARD = gql`
  mutation DeleteHubDashCard($id: Int!) {
    deleteHubDashCard(id: $id) {
      id
    }
  }
`
export const CREATE_HUBDASH_CARD = gql`
  mutation CreateHubDashCard(
    $input: CreateHubDashCardInput!
    $linkedResources: [CreateHubDashCardToHubResourceInput!]
  ) {
    createHubDashCard(input: $input, linkedResources: $linkedResources) {
      id
    }
  }
`

export const CREATE_COLOR = gql`
  mutation CreateColor($input: CreateHubDashColorMapInput!) {
    createHubDashColorMap(input: $input) {
      id
      name
      description
      colorName
      icon
      type
    }
  }
`

export const UPDATE_COLOR = gql`
  mutation UpdateColor($id: Int!, $input: UpdateHubDashColorMapInput!) {
    updateHubDashColorMap(id: $id, input: $input) {
      id
      name
      description
      colorName
      icon
    }
  }
`

export const DELETE_COLOR = gql`
  mutation DeleteColor($id: Int!) {
    deleteHubDashColorMap(id: $id) {
      id
    }
  }
`

export const UPDATE_HUBDASH_CARD = gql`
  mutation UpdateHubDashCard(
    $id: Int!
    $relationId: Int!
    $input: UpdateHubDashCardInput!
    $linkedResources: [CreateHubDashCardToHubResourceInput!]
  ) {
    updateHubDashCard(
      id: $id
      relationId: $relationId
      input: $input
      linkedResources: $linkedResources
    ) {
      id
    }
  }
`

export const UPDATE_CARDS_IN_LAYOUT = gql`
  mutation UpdateCardsInLayout($layoutId: Int!, $layoutData: JSON!) {
    updateCardsInLayout(layoutId: $layoutId, layoutData: $layoutData)
  }
`
export const HUB_DASH_CARD_LINKED_RESOURCES = gql`
  query GetHubDashCardLinkedResources($hubDashCardId: Int!) {
    hubDashCardToHubResourcesByCardId(hubDashCardId: $hubDashCardId) {
      id
      resourceId
      resourceType
      resourceName
      resolvedResourceId
      linkMetaData
    }
  }
`

export const CREATE_LAYOUT = gql`
  mutation CreateHubdashLayout($input: CreateHubDashLayoutInput!) {
    createHubDashLayout(input: $input) {
      id
    }
  }
`

export const UPDATE_LAYOUT = gql`
  mutation UpdateHubdashLayout($id: Int!, $input: UpdateHubDashLayoutInput!) {
    updateHubDashLayout(id: $id, input: $input) {
      id
    }
  }
`
export const DELETE_LAYOUT = gql`
  mutation DeleteHubdashLayout($id: Int!) {
    deleteHubDashLayout(id: $id) {
      id
    }
  }
`
