import { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { Drawer, Tab, Tabs, TextField } from '@mui/material'

import Empty from 'src/components/Empty'
import {
  getRecordsForTableByTextSearch,
  getUserBasesAndTables,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button'
import Loading from 'src/components/Library/Loading'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import { CountBadge } from '../CountBadge/CountBadge'

import { SearchGridWrapper } from './SearchGridWrapper'

export const SearchExpand = ({
  searchExpandOpen,
  setSearchExpandOpen,
}: {
  searchExpandOpen: boolean
  setSearchExpandOpen: (open: boolean) => void
}) => {
  const [token] = useHubDashStore((state) => [state.token])

  const [searchVal, setSearchVal] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(null)

  const [basesList, setBasesList] = useState([])
  const [tablesList, setTablesList] = useState([])
  const [tablesData, setTablesData] = useState([])

  const [selectedBase, setSelectedBase] = useState(null)
  const [selectedTable, setSelectedTable] = useState({
    name: 'All tables',
    id: 0,
  })

  const [loadingBasesList, setLoadingBasesList] = useState(true)

  const [init, setInit] = useState(true)

  useEffect(() => {
    const getBasesAndTables = async () => {
      const basesData = await getUserBasesAndTables({ token })
      setBasesList(basesData)
      setLoadingBasesList(false)
    }
    getBasesAndTables()
  }, [])

  useEffect(() => {
    setTablesList(selectedBase?.tables || [])
  }, [selectedBase])

  const onSearch = async () => {
    setSearchLoading(true)
    setInit(false)
    setTablesData([])
    setActiveTab(null)

    const callApi = async (table: any, token: string, searchVal: string) => {
      const response = await getRecordsForTableByTextSearch({
        token,
        tableId: table.id,
        searchValue: searchVal,
      })
      if (!response.count) return null
      return {
        records: response.results,
        table: table,
      }
    }
    if (selectedTable.id) {
      const response = await callApi(selectedTable, token, searchVal)
      if (response) {
        setTablesData([response])
        setActiveTab(String(response.table.id))
      }
    } else {
      const responses = await Promise.all(
        tablesList.map(async (table) => {
          const response = await callApi(table, token, searchVal)
          return response
        }),
      )
      const cleanResponses = responses.filter((response) => response !== null)
      if (cleanResponses.length > 0) {
        setTablesData(cleanResponses)
        setActiveTab(String(cleanResponses[0].table.id))
      }
    }

    setSearchLoading(false)
  }

  const tableOptions = [{ name: 'All tables', id: 0 }, ...tablesList]

  useEffect(() => {
    setSelectedTable({
      name: 'All tables',
      id: 0,
    })
  }, [selectedBase])

  const showPlaceHolder =
    searchLoading ||
    (tablesData.length === 0 && !init && !searchLoading) ||
    init

  return (
    <Drawer
      anchor={'right'}
      open={searchExpandOpen}
      onClose={() => setSearchExpandOpen(false)}
      PaperProps={{
        style: {
          width: '80%',
          height: '100%',
        },
      }}
    >
      <div className="flex justify-between border-b border-gray-200 px-4 py-2">
        <div className="flex items-center gap-4">
          <div className="">
            <p className="text-3xl">Base Search</p>
          </div>
        </div>
        <button
          onClick={() => setSearchExpandOpen(false)}
          className="cursor-pointer rounded-lg hover:bg-gray-100"
        >
          <XMarkIcon className="h-10 w-10 text-gray-500" />
        </button>
      </div>
      <div className="mt-2 flex gap-2 p-2">
        <Autocomplete
          getOptionLabel={(option) => option?.name}
          groupBy={(option) => option.workspace.name}
          options={basesList}
          value={selectedBase}
          loading={loadingBasesList}
          onChange={(_, newValue) => setSelectedBase(newValue)}
          renderInput={(params) => <TextField {...params} label="Base" />}
          renderGroup={(params) => (
            <li key={params.key}>
              <div className="border-t border-gray-300 bg-white p-2 px-4 pt-4 font-bold">
                {params.group}
              </div>
              <ul>{params.children}</ul>
            </li>
          )}
          className="w-[50%]"
          size="small"
        />
        <Autocomplete
          getOptionLabel={(option) => option?.name}
          options={tableOptions}
          value={selectedTable}
          onChange={(_, newValue) => setSelectedTable(newValue)}
          renderInput={(params) => <TextField {...params} label="Table" />}
          className="w-[50%]"
          size="small"
        />
        <SearchField
          value={searchVal}
          onChange={setSearchVal}
          placeholder={
            !selectedBase
              ? 'Select a base'
              : !selectedTable
                ? 'Select a table'
                : 'Search'
          }
          debounced={true}
          disabled={!selectedBase || !selectedTable}
          onEnterKeyDown={() => {
            if (searchVal.length === 0 || !selectedBase || !selectedTable)
              return
            onSearch()
          }}
        />
        <Button
          className="w-48"
          onClick={onSearch}
          disabled={searchVal.length === 0 || !selectedBase || !selectedTable}
        >
          Search
        </Button>
      </div>
      {!showPlaceHolder && (
        <>
          <Tabs
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, newValue) => {
              if (newValue) {
                setActiveTab(String(newValue))
              }
            }}
          >
            {tablesData.map((tableData) => (
              <Tab
                key={tableData.table.id.toString()}
                value={tableData.table.id.toString()}
                label={
                  <div className="flex items-center justify-center gap-2">
                    <p>{tableData.table.name}</p>
                    <CountBadge count={tableData.records.length} />
                  </div>
                }
              />
            ))}
          </Tabs>
          {activeTab && (
            <SearchGridWrapper
              tableData={tablesData.find(
                (tableData) => tableData.table.id === parseInt(activeTab),
              )}
              token={token}
              baseData={selectedBase}
              searchVal={searchVal}
            />
          )}
        </>
      )}
      {showPlaceHolder && (
        <div className="flex h-full w-full items-center justify-center">
          {searchLoading && <Loading />}
          {tablesData.length === 0 && !init && !searchLoading && (
            <Empty
              defaultIcon
              title="No results found"
              description="Please modify your search and try again"
            />
          )}
          {init && !searchLoading && (
            <Empty
              defaultIcon
              title="Base Search"
              description="Select a base, table and enter a search term"
            />
          )}
        </div>
      )}
    </Drawer>
  )
}
