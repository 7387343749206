import { useEffect, type FC } from 'react'

import { TextField, Tooltip } from '@mui/material'

import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import type { HubDashUser } from '../lib/types'
import type {
  HubDashMembership,
  HubDashMembershipGroup,
} from '../MembershipsCell/MembershipsCell'

interface UserAutocompleteProps {
  memberships: HubDashMembership[]
  groups: HubDashMembershipGroup[]
}

const UserAutocomplete: FC<UserAutocompleteProps> = ({
  memberships,
  groups,
}) => {
  const { currentUser } = useAuth()
  const isSupport = currentUser.isClientAlias

  const [
    layoutData,
    userList,
    setUserList,
    selectedUserItems,
    setSelectedUserItems,
    setSelectedUsers,
  ] = useHubDashStore((state) => [
    state.layoutData,
    state.userList,
    state.setUserList,
    state.selectedUserItems,
    state.setSelectedUserItems,
    state.setSelectedUsers,
  ])

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    const groupsList: HubDashUser[] = groups.map((group) => {
      return {
        id: group?.id,
        name: group?.name,
        avatarUrl: '',
        baserowUserId: null,
        listType: 'Group',
      }
    })
    const usersList: HubDashUser[] = memberships.map((membership) => {
      return {
        id: membership?.user?.id,
        name: membership?.user?.name,
        avatarUrl: membership?.user?.avatarUrl,
        baserowUserId: membership?.user?.baserowUserId,
        groups: membership?.membershipGroups?.map((group) => {
          return {
            id: group?.membershipGroup?.id,
            name: group?.membershipGroup?.name,
          }
        }),
        listType: 'User',
      }
    })
    const combinedList = [...groupsList, ...usersList]
    setUserList(combinedList)
  }, [memberships, groups, setUserList])

  useEffect(() => {
    if (selectedUserItems.length > 0) {
      const cleanUsers = selectedUserItems.map((item) => {
        if (item.listType === 'Group') {
          const groupUsers = userList.filter((u) => {
            if (u.listType === 'User') {
              return u.groups.some((group) => group.id === item.id)
            } else {
              return false
            }
          })
          return groupUsers.map((user) => user.name)
        } else {
          return item.name
        }
      })
      const flatUsers = cleanUsers.flat()
      const uniqueUsers = [...new Set(flatUsers)]

      setSelectedUsers(uniqueUsers)
    } else {
      setSelectedUsers([])
    }
  }, [selectedUserItems, userList])

  useEffect(() => {
    if (
      (layoutData?.userFilterType === 'CURRENT' ||
        layoutData?.userFilterType === 'ANY') &&
      !isSupport
    ) {
      setSelectedUserItems(
        userList.filter(
          (user) =>
            user.id === currentUser.userData.id && user.listType === 'User',
        ),
      )
    }
  }, [
    layoutData?.userFilterType,
    currentUser.userData.id,
    userList,
    isSupport,
    setSelectedUserItems,
  ])

  const userFilterEnabled =
    layoutData?.allowUserFilterOverride || layoutData?.userFilterType === 'ANY'

  return (
    <Tooltip
      title={
        !layoutData?.allowUserFilterOverride &&
        layoutData?.userFilterType === 'CURRENT'
          ? 'User filtering is locked for this layout'
          : ''
      }
    >
      <div
        className="w-full flex-grow"
        data-intercom-target="hubdash-user-select"
      >
        <AutoComplete
          disabled={!userFilterEnabled}
          options={userList}
          size="small"
          className="w-full"
          getOptionLabel={(option) => option?.name}
          groupBy={(option) => option.listType}
          renderGroup={(params) => (
            <li key={params.key}>
              <div>
                <div className="px-8">
                  <div
                    className={`p-2 text-gray-500 ${
                      params.group === 'User'
                        ? 'border-t-2 border-gray-300'
                        : ''
                    }`}
                  ></div>
                </div>
                <div className="pl-8 font-bold">{params.group}s</div>
              </div>
              <div className="pl-4">{params.children}</div>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Users" className="z-10 bg-white" />
          )}
          limitTags={3}
          onChange={(_, newValue) => {
            setSelectedUserItems(newValue)
            trackEvent('HubDash', 'Select Users')
          }}
          value={selectedUserItems}
          disableCloseOnSelect
          multiple
        />
      </div>
    </Tooltip>
  )
}

export default UserAutocomplete
