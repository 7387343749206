import { useEffect, useState } from 'react'

import Drawer from '@mui/material/Drawer'

import {
  enrichRecords,
  getFieldsForTable,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import RecordExpand from 'src/components/HubDash/RecordExpand/RecordExpand'
import RecordGrid from 'src/components/HubDash/RecordGrid/RecordGrid'
import Loading from 'src/components/Library/Loading'

export const SearchGridWrapper = ({
  tableData,
  token,
  baseData,
  searchVal,
}) => {
  const [tableFields, setTableFields] = useState([])
  const [enrichedRecords, setEnrichedRecords] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [commentsOpen, setCommentsOpen] = useState(true)

  useEffect(() => {
    const getTableFields = async () => {
      const fields = await getFieldsForTable({
        token,
        tableId: tableData.table.id,
      })
      const structuredFields = fields.map((field) => ({
        ...field,
        viewOrder: field.order,
        viewHidden: false,
      }))
      setTableFields(structuredFields)
      const enrichedData = await enrichRecords({
        records: tableData.records,
        tableFieldsData: fields,
        token,
        tableId: tableData.table.id,
        workspaceId: baseData.workspace.id,
      })
      setEnrichedRecords(enrichedData)
    }
    getTableFields()
  }, [tableData.table.id, tableData.records, token, tableData.workspaceId])

  const cardSocket = {
    records: enrichedRecords,
    activeRecords: [],
    inactiveRecords: [],
    table: {
      id: tableData.table.id,
      name: tableData.table.name,
      fields: tableFields,
      primaryField: tableFields[0],
    },
    view: null,
    base: baseData,
    baserowErrorsState: [],
  }

  const card = null

  return (
    <>
      <div className="h-full w-full p-4">
        {tableFields.length === 0 && (
          <div className="grid place-items-center pt-48">
            <Loading />
          </div>
        )}
        {tableFields.length > 0 && (
          <RecordGrid
            cardSocket={cardSocket}
            card={null}
            isColorFiltered={false}
            isAction={false}
            setSelectedRecord={setSelectedRecord}
            selectedBorderColor={''}
            selectedBackgroundColor={''}
            recordSearchVal={''}
          ></RecordGrid>
        )}
      </div>
      {selectedRecord && (
        <Drawer
          anchor={'right'}
          open={true}
          onClose={() => setSelectedRecord(null)}
          PaperProps={{
            style: {
              width: '80%',
              height: '100%',
            },
          }}
        >
          <div className="flex justify-between border-b border-gray-200 px-4 py-2">
            <div className="flex items-center gap-4">
              <div className="">
                <p className="text-3xl">{searchVal}</p>
              </div>
              <div className="flex flex-col border-l border-gray-300 pl-4">
                <p className="text text-gray-500">{tableData.table.name}</p>
                <p className="text text-gray-500">All Fields</p>
              </div>
            </div>
          </div>
          <div
            className="flex h-full w-full flex-col overflow-clip"
            style={{ height: 'calc(100% - 65px)' }}
          >
            <div className="flex h-full w-full">
              <RecordExpand
                cardSocket={cardSocket}
                selectedRecord={selectedRecord}
                clearWrappedRecord={() => setSelectedRecord(null)}
                setCommentsOpen={setCommentsOpen}
                commentsOpen={commentsOpen}
                card={card}
              />
            </div>
          </div>
        </Drawer>
      )}
    </>
  )
}
