import React, { FC } from 'react'
interface CountBadgeProps {
  count: number | string
  containerClassName?: string
  containerStyle?: React.CSSProperties
  textClassName?: string
  textStyle?: React.CSSProperties
}
export const CountBadge: FC<CountBadgeProps> = ({
  count,
  containerClassName,
  containerStyle,
  textClassName,
  textStyle,
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full bg-indigo-600 px-2 py-1 ${containerClassName ?? ''}`}
      style={containerStyle}
    >
      <p
        className={`text-[10px] text-white ${textClassName}`}
        style={textStyle}
      >
        {count}
      </p>
    </div>
  )
}
