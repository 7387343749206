import type { Dispatch, FC, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as IconSet from '@heroicons/react/24/outline'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { Tooltip } from '@mui/material'
import {
  AllCommunityModule,
  ClientSideRowModelModule,
  ColDef,
  GetRowIdParams,
  ModuleRegistry,
  themeQuartz,
  type ISetFilterParams,
} from 'ag-grid-community'
import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  SetFilterModule,
} from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import 'src/styles/recordGrid.css'

import FieldRenderer from 'src/components/HubDash/FieldRenderer'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import type {
  CardSocket,
  CardSocketRecord,
  CardSocketTableField,
} from 'src/components/HubDash/lib/baserow/useBaserowSocket.tsx'

import { BaserowFieldType } from '../lib/enums'
import type { HubDashCardType } from '../lib/types'

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  AllCommunityModule,
  ColumnsToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  SetFilterModule,
])

interface RecordListProps {
  cardSocket: CardSocket
  card: HubDashCardType
  isColorFiltered: boolean
  setSelectedRecord: Dispatch<SetStateAction<any>> // not typed yet
  selectedBorderColor: string
  selectedBackgroundColor: string
  recordSearchVal: string
  isAction: boolean
}

const recordGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

const isJSONType = (value: unknown) => {
  const isString = typeof value === 'string'
  if (!isString) return false
  try {
    JSON.parse(value as string)
    return true
  } catch (e) {
    return false
  }
}

const RecordGrid: FC<RecordListProps> = ({
  cardSocket,
  card,
  isColorFiltered,
  setSelectedRecord,
  selectedBorderColor,
  selectedBackgroundColor,
  recordSearchVal,
  isAction,
}) => {
  const [groupFields, setGroupFields] = useState([])
  const [hiddenFields, setHiddenFields] = useState([])
  const initialLoad = useRef(true)
  const gridApiRef = useRef(null)

  let records = cardSocket.records

  if (isColorFiltered) {
    records = isAction ? cardSocket.activeRecords : cardSocket.inactiveRecords
  }

  const getRowId = useCallback(
    (params: GetRowIdParams) => String(params.data.id),
    [],
  )

  const valueGetter = (field: CardSocketTableField, data: CardSocketRecord) => {
    if (!data) return null

    const cellValue = data.getCellValue(field.name)
    if (cellValue === null) return null
    if (Array.isArray(cellValue) && cellValue.length === 0) return null
    if (field.type === 'number' || field.type === 'rating') {
      return Number(cellValue)
    }
    if (typeof cellValue !== 'string') {
      return JSON.stringify(cellValue)
    }
    const datePattern = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}Z)?$/
    if (cellValue.match(datePattern)) {
      return new Date(cellValue)
    }
    return data.getCellValueAsString(field.name)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      enableRowGroup: true,
      filter: true,
      minWidth: 200,
      flex: 1,
    }
  }, [])

  const filterByColor = (record) => {
    const hasBorderColorMatch =
      selectedBorderColor === null ||
      selectedBorderColor === '' ||
      selectedBorderColor === 'white' ||
      (record?.decorators?.left_border_color?.length > 0 &&
        record.decorators.left_border_color.some(
          (color) => color.color === selectedBorderColor,
        ))

    const hasBackgroundColorMatch =
      selectedBackgroundColor === null ||
      selectedBackgroundColor === '' ||
      selectedBackgroundColor === 'white' ||
      (record?.decorators?.background_color?.length > 0 &&
        record.decorators.background_color.some(
          (color) => color.color === selectedBackgroundColor,
        ))

    return hasBorderColorMatch && hasBackgroundColorMatch
  }

  const filteredRecords = records.filter((record) => {
    return filterByColor(record)
  })

  const colDefs = useMemo(() => {
    // decoratorShowed flag is used to show the decorator only once
    let decoratorShowed = false
    const primaryField = cardSocket.table.fields.find((field) => field.primary)
    const primaryFieldBeingHidden =
      primaryField && hiddenFields.includes(primaryField.name)

    const shouldShowDecorator = (field) => {
      if (primaryFieldBeingHidden) {
        //if primary field being hidden just return true for the fist and only the first visible field
        return !hiddenFields.includes(field.name) && !decoratorShowed
      }

      return field.primary && !decoratorShowed
    }

    const columns = cardSocket.table.fields
      .filter((field) => !field.viewHidden && field?.formula_type !== 'button')
      .map((field: CardSocketTableField) => {
        const showDecorator = shouldShowDecorator(field)
        if (showDecorator) decoratorShowed = true

        //link sorting data on initialload
        let sorting = null
        const sortings = cardSocket.view?.sortings
        if (sortings?.length > 0 && initialLoad.current) {
          const index = sortings.findIndex(
            (sortData) => sortData.field === field.id,
          )
          if (index !== -1) {
            sorting = {
              index: index,
              order: sortings[index].order.toLowerCase(),
            }
          }
        }
        //link groupby data on initialload
        let groupBy = null
        const groupBys = cardSocket.view?.group_bys
        if (groupBys?.length > 0 && initialLoad.current) {
          const index = groupBys.findIndex(
            (groupData) => groupData.field === field.id,
          )
          if (index !== -1) {
            groupBy = {
              index: index,
              order: groupBys[index].order.toLowerCase(),
            }
          }
        }

        const result: ColDef<CardSocketRecord, string | number | Date> = {
          ...(field.primary && {
            pinned: 'left',
            lockPinned: true,
            minWidth: 300,
          }),
          ...(sorting && {
            sort: sorting.order,
            sortIndex: sorting.index,
          }),
          ...(groupBy && {
            sort: groupBy.order,
            sortIndex: groupBy.index,
            rowGroup: true,
            rowGroupIndex: groupBy.index,
          }),
          headerName: field.name,
          // @ts-expect-error: field can be string
          field: field.name,
          valueGetter: ({ data }) => {
            return valueGetter(field, data)
          },
          filter: true,
          filterParams: {
            cellRenderer: ({ value }: { value: string | number | Date }) => {
              if (value === '(Select All)') {
                return <div>(Select All)</div>
              } else if (value === null) {
                return <div>(Empty)</div>
              }
              const cellValue = isJSONType(value)
                ? JSON.parse(value as string)
                : value
              return (
                <div className="flex h-full w-full flex-col justify-center overflow-clip p-1">
                  <FieldRenderer
                    field={field}
                    record={null}
                    value={cellValue}
                    isName={false}
                  />
                </div>
              )
            },
          } as ISetFilterParams,
          cellRenderer: ({ data }: { data: CardSocketRecord }) => {
            if (!data) return ''
            return (
              <div
                data-testid="record-list-row"
                className="flex h-full w-full flex-col justify-center overflow-clip"
              >
                <FieldRenderer field={field} record={data} isName={false} />
                {showDecorator &&
                  data.decorators?.left_border_color.length > 0 &&
                  isAction && (
                    <div className="flex items-center gap-2">
                      <>
                        {data.decorators.left_border_color.map((color) => {
                          const colorIconName = card.cardColors.find(
                            (c) => c.colorName === color.color,
                          )
                          const ColorIcon = colorIconName
                            ? IconSet[colorIconName.icon]
                            : null
                          return (
                            <div
                              className="grid h-6 w-6 place-items-center rounded-md border border-white"
                              key={color.id}
                              style={{
                                backgroundColor: getHexForColor(color.color),
                              }}
                            >
                              {ColorIcon && (
                                <ColorIcon className="h-5 w-5 text-white" />
                              )}
                            </div>
                          )
                        })}
                      </>
                    </div>
                  )}
              </div>
            )
          },
        }
        return result
      })
    const numberOfComments: ColDef<CardSocketRecord, string | number | Date> = {
      headerName: '',
      headerComponent: () => (
        <Tooltip title={'Number of comments'}>
          <ChatBubbleIcon style={{ fontSize: 14, color: '#bfbfbf' }} />
        </Tooltip>
      ),
      field: 'commentCount',
      pinned: 'left',
      lockPinned: true,
      maxWidth: 40,
      sortable: false,
      resizable: false,
      cellStyle: { background: '#f3f4f6' },
      cellRenderer: ({ data }: { data: CardSocketRecord }) => {
        return (
          <div className="flex h-full w-full items-center justify-center">
            <p
              className="text-indigo-500"
              data-testid={`hubdash-record-count-${data.commentCount}`}
            >
              {data.commentCount}
            </p>
          </div>
        )
      },
    }
    columns.unshift(numberOfComments)

    if (initialLoad.current) {
      initialLoad.current = false
    }

    return columns
  }, [cardSocket.table.fields, groupFields, filteredRecords, hiddenFields])

  const groupRowRendererParams = useMemo(() => {
    return {
      suppressCount: true,
      marginTop: 20,
      innerRenderer: (params) => {
        const node = params.node
        const field = cardSocket.table.fields.find((f) => f.name === node.field)
        if (node.allLeafChildren.length === 0) return null
        const data = node.allLeafChildren[0].data
        const fieldInData = data.fields.find((f) => f.id === field.id)
        const value = data.getCellValue(fieldInData.name)
        let emptyData = false
        const arrayFields = [
          BaserowFieldType.FILE,
          BaserowFieldType.MULTIPLE_COLLABORATORS,
          BaserowFieldType.MULTIPLE_SELECT,
        ]
        if (
          !value ||
          (arrayFields.includes(field.type) && value.length === 0)
        ) {
          emptyData = true
        }

        return (
          <div className="flex items-center justify-between gap-6">
            <div className="flex flex-col gap-1">
              <p className="color text-[8px] font-medium uppercase">
                {field.name}
              </p>
              <div className="flex items-center">
                {emptyData ? (
                  <p>(Empty)</p>
                ) : (
                  <FieldRenderer field={field} record={data} isName={false} />
                )}
              </div>
            </div>
            <p>
              <span className="text-[10px] font-medium">Count</span>{' '}
              {node.allLeafChildren.length}
            </p>
          </div>
        )
      },
    }
  }, [cardSocket.table.fields])

  const onColumnRowGroupChanged = (params) => {
    const rowGroupColumns = params.api.getRowGroupColumns() // Get current group columns
    if (rowGroupColumns.length > 0) {
      const groupFields = rowGroupColumns.map((column) => column.colDef.field)
      setGroupFields(groupFields)
      if (params.column) {
        const columnState = params.api.getColumnState()
        // Ensure the grouped columns remain visible
        const updatedColumnState = columnState.map((state) =>
          state.colId === params.column.colId
            ? { ...state, hide: false }
            : state,
        )
        params.api.applyColumnState({ state: updatedColumnState })
      }
    } else {
      setGroupFields([])
    }
  }

  const getRowStyle = useCallback(
    (params) => {
      const { node } = params
      const styleObj = {}
      if (groupFields.length > 0) {
        if (node.group) {
          //make gap for group expandable row
          const gap = 10
          const leftGap = gap * node.level
          styleObj['marginLeft'] = `${leftGap + 10}px`
          styleObj['maxWidth'] = `calc(100% - ${leftGap + 20}px)`

          //first group expandable should not have 30px top gap
          if (node.level === 0 && node.rowIndex === 0) {
            styleObj['borderTop'] = '10px solid white'
          } else if (node.level === 0) {
            styleObj['borderTop'] = '30px solid white'
          } else {
            styleObj['borderTop'] = '10px solid white'
          }

          //remove bottom border radius for last expanded group row
          if (node.expanded && node.level === groupFields.length - 1) {
            setTimeout(() => {
              const expandableCell = params.api.getRowNode(node.id)
                ? document.querySelector(
                    `[row-index="${node.rowIndex}"] .ag-cell-expandable`,
                  )
                : null

              if (expandableCell instanceof HTMLElement) {
                expandableCell.style.borderBottomLeftRadius = '0px'
                expandableCell.style.borderBottomRightRadius = '0px'
              }
            }, 50)
          }
        } else {
          if (params.data?.decorators?.background_color?.length > 0) {
            styleObj['background'] = getHexForColor(
              params.data.decorators.background_color[0].color,
            )
          }
        }
      } else {
        if (params.data?.decorators?.background_color?.length > 0) {
          styleObj['background'] = getHexForColor(
            params.data.decorators.background_color[0].color,
          )
        }
      }
      styleObj['cursor'] = 'pointer'
      return styleObj
    },
    [groupFields],
  )

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.resetRowHeights()
    }
  }, [filteredRecords])

  useEffect(() => {
    const viewportBody = document.querySelector(
      '.ag-body-viewport',
    ) as HTMLElement
    const viewport = document.querySelector(
      '.ag-body-viewport .ag-viewport',
    ) as HTMLElement
    const pinnedLeft = document.querySelector(
      '.ag-body-viewport .ag-pinned-left-cols-container',
    ) as HTMLElement
    const header = document.querySelector('.ag-root .ag-header') as HTMLElement
    const horizontalScrollBarLeftSpacer = document.querySelector(
      '.ag-horizontal-left-spacer',
    ) as HTMLElement
    const horizontalScrollBarRightSpacer = document.querySelector(
      '.ag-horizontal-right-spacer',
    ) as HTMLElement

    if (groupFields.length > 0) {
      if (viewport && viewportBody) {
        viewportBody.style.paddingLeft = '10px'
        viewportBody.style.paddingRight = '10px'
        viewport.style.borderRight = '1px solid #e5e7eb'
        viewport.style.borderLeft = '1px solid #e5e7eb'
        viewport.style.borderBottom = '1px solid #e5e7eb'
        viewport.style.minHeight = 'unset'
      }
      if (pinnedLeft) {
        pinnedLeft.style.borderLeft = '1px solid #e5e7eb'
        pinnedLeft.style.marginLeft = `${(groupFields.length - 1) * 10}px`
      }
      if (header) {
        header.style.paddingLeft = `${groupFields.length * 10}px`
        header.style.paddingRight = '10px'
      }
      if (horizontalScrollBarLeftSpacer && horizontalScrollBarRightSpacer) {
        horizontalScrollBarLeftSpacer.style.marginLeft = `${groupFields.length * 10}px`
        horizontalScrollBarRightSpacer.style.marginRight = '10px'
      }
    } else {
      if (viewport && viewportBody) {
        viewportBody.style.paddingLeft = '0px'
        viewportBody.style.paddingRight = '0px'
        viewport.style.borderRight = 'none'
        viewport.style.borderLeft = 'none'
        viewport.style.borderBottom = 'none'
        viewport.style.minHeight = '100%'
      }
      if (pinnedLeft) {
        pinnedLeft.style.borderLeft = 'none'
        pinnedLeft.style.marginLeft = '0px'
      }
      if (header) {
        header.style.paddingLeft = '0px'
        header.style.paddingRight = '0px'
      }
      if (horizontalScrollBarLeftSpacer && horizontalScrollBarRightSpacer) {
        horizontalScrollBarLeftSpacer.style.marginLeft = '0px'
        horizontalScrollBarRightSpacer.style.marginRight = '0px'
      }
    }
  }, [groupFields])

  const getRowHeight = (params) => {
    //first group expandable should not have 30px top gap
    if (
      params.node.group &&
      params.node.level === 0 &&
      params.node.rowIndex === 0
    ) {
      return 58
    }
    if (params.node.group && params.node.level === 0) {
      return 78
    }
    if (params.node.group) {
      return 58
    }
    return 80
  }

  return (
    <div className="record-grid-wrapper hide-scrollbar ag-theme-quartz flex h-full flex-col gap-2 overflow-scroll pb-4">
      <AgGridReact<CardSocketRecord>
        rowData={filteredRecords}
        quickFilterText={recordSearchVal}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onRowClicked={({ data }) => {
          if (!data) return
          setSelectedRecord(records.find((record) => record.id === data.id))
        }}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        theme={recordGridTheme}
        getRowStyle={getRowStyle}
        loadThemeGoogleFonts={false}
        groupDisplayType="groupRows"
        rowGroupPanelShow="never"
        groupRowRendererParams={groupRowRendererParams}
        groupDefaultExpanded={-1}
        onColumnRowGroupChanged={onColumnRowGroupChanged}
        onSortChanged={() => {
          if (gridApiRef.current) {
            gridApiRef.current.resetRowHeights()
          }
        }}
        onGridReady={({ api }) => {
          gridApiRef.current = api
        }}
        onColumnVisible={(params) => {
          if (params.column.isVisible()) {
            setHiddenFields(
              hiddenFields.filter(
                (field) => field !== params.column.getColId(),
              ),
            )
          } else {
            setHiddenFields([...hiddenFields, params.column.getColId()])
          }
        }}
      />
    </div>
  )
}

export default RecordGrid
