import { useEffect, useState, type FC } from 'react'

import {
  Cog6ToothIcon,
  DocumentPlusIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/outline'
import { TextField, Tooltip } from '@mui/material'
import { GridSearchIcon } from '@mui/x-data-grid'
import { MembershipRole } from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import LayoutSettingsDrawer from 'src/components/HubDash/LayoutSettingsDrawer/LayoutSettingsDrawer'
import MembershipsCell from 'src/components/HubDash/MembershipsCell'
import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import type { HubDashLayout } from '../HubDashLayoutListCell'
import { HubDashLayoutSearchAccessType } from '../LayoutSettingsDrawer/LayoutSettingsDrawer'
import type { HubDashCardType, HubDashUser } from '../lib/types'
import { SearchExpand } from '../SearchExpand/SearchExpand'

interface HubDashHeaderProps {
  layouts: HubDashLayout[]
}

const HubDashHeader: FC<HubDashHeaderProps> = ({ layouts }) => {
  const { hasRole, currentUser } = useAuth()
  const isSupport = currentUser.isClientAlias
  const adminRoles: MembershipRole[] = ['ADMIN', 'OWNER']
  const isAdmin = hasRole(adminRoles)

  const { layoutId } = useParams()

  const [
    layout,
    setLayout,
    layoutData,
    setLayoutData,
    isUnlocked,
    setIsUnlocked,
  ] = useHubDashStore((state) => [
    state.layout,
    state.setLayout,
    state.layoutData,
    state.setLayoutData,
    state.isUnlocked,
    state.setIsUnlocked,
  ])

  const [editMode, setEditMode] = useState<boolean>(false)
  const [searchExpandOpen, setSearchExpandOpen] = useState<boolean>(false)

  const { trackEvent } = useAnalytics()
  const params = useParams()
  const location = useLocation()

  const [layoutSettingsOpen, setLayoutSettingsOpen] = useState(false)

  const handleLayoutChange = (newHubDashLayout: HubDashLayout) => {
    if (newHubDashLayout) {
      navigate(
        routes.hubDashWithId({
          layoutId: newHubDashLayout.id,
        }),
      )
    }
  }

  useEffect(() => {
    if (layouts.length > 0) {
      const structuredLayouts = layouts.map((layout) => {
        return {
          ...layout,
          cards: layout.hubDashCards.map((card) => {
            return {
              id: card.id,
              relationId: card.id,
              hubDashLayoutId: card.hubDashLayoutId,
              position: card.position,
              name: card.name,
              description: card.description,
              type: card.type,
              source: card.source,
              cardData: card.cardData,
              cardSettings: card.cardSettings,
              cardColors: card.hubDashColorMap,
              linkedResources: card.linkedResources,
            } as HubDashCardType
          }),
        }
      })
      if (!layout || layout !== Number(layoutId)) {
        if (layoutId) {
          const selectedLayout = structuredLayouts.find(
            (l) => l.id === Number(layoutId),
          )
          setLayout(Number(layoutId))
          setLayoutData(selectedLayout)
        } else {
          navigate(
            routes.hubDashWithId({
              layoutId: Number(layoutId) || layouts[0].id,
              ...params,
            }),
          )
        }
      } else {
        const selectedLayout = structuredLayouts.find((l) => l.id === layout)
        setLayoutData(selectedLayout || structuredLayouts[0])
      }
    }
  }, [layouts, layoutId, params, location])

  const showSearch =
    layoutData?.searchAccessType === HubDashLayoutSearchAccessType.ALL ||
    (layoutData?.searchAccessType === HubDashLayoutSearchAccessType.ADMINONLY &&
      isAdmin)

  return (
    <div className="flex h-10 w-full justify-between gap-2">
      {layouts.length === 0 ? (
        <>
          {isAdmin && (
            <Button
              className="w-48"
              onClick={() => {
                setLayoutSettingsOpen(true)
              }}
              buttonDataTestId="create-layout"
            >
              Create Layout
            </Button>
          )}
        </>
      ) : (
        <div className="flex w-full justify-between gap-2 pr-2">
          <div className="flex w-full gap-2">
            <div
              data-intercom-target="hubdash-layout-select"
              data-testid="hubdash-layout-select"
            >
              <AutoComplete
                className="w-72"
                size="small"
                options={layouts}
                getOptionLabel={(option: HubDashLayout) => option?.name || ''}
                renderInput={(params) => <TextField {...params} required />}
                onChange={(_, newValue: HubDashLayout) => {
                  handleLayoutChange(newValue)
                }}
                value={layouts.find((l) => l?.id === layout) || ''}
              />
            </div>

            <MembershipsCell />
          </div>
          <div className="flex gap-2">
            {showSearch && (
              <Tooltip title="Base Search">
                <span data-intercom-target="base-search">
                  <IconButton
                    onClick={() => {
                      setSearchExpandOpen(true)
                    }}
                    className="h-10 w-10"
                  >
                    <GridSearchIcon className="h-6 w-6" />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {isAdmin && (
              <div className="flex gap-2">
                <Tooltip title="Add Layout">
                  <span
                    data-intercom-target="add-layout"
                    data-testid="add-layout"
                  >
                    <IconButton
                      onClick={() => {
                        setEditMode(false)
                        setLayoutSettingsOpen(true)
                      }}
                      className="h-10 w-10"
                    >
                      <DocumentPlusIcon className="h-6 w-6" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={`${isUnlocked ? 'Lock' : 'Unlock'} Layout`}>
                  <span
                    data-intercom-target={`${isUnlocked ? 'lock' : 'unlock'}-layout`}
                    data-testid={`${isUnlocked ? 'lock' : 'unlock'}-layout`}
                  >
                    <IconButton
                      onClick={() => {
                        setIsUnlocked(!isUnlocked)
                        trackEvent(
                          'HubDash',
                          `${isUnlocked ? 'Lock' : 'Unlock'} Layout`,
                        )
                      }}
                      className="h-10 w-10"
                    >
                      {isUnlocked ? (
                        <LockOpenIcon className="h-6 w-6" />
                      ) : (
                        <LockClosedIcon className="h-6 w-6" />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Layout Settings">
                  <span data-intercom-target="layout-settings">
                    <IconButton
                      onClick={() => {
                        setEditMode(true)
                        setLayoutSettingsOpen(true)
                        trackEvent('HubDash', 'Click Layout Settings')
                      }}
                      className="h-10 w-10"
                    >
                      <Cog6ToothIcon className="h-6 w-6" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      <LayoutSettingsDrawer
        layoutSettingsOpen={layoutSettingsOpen}
        setLayoutSettingsOpen={setLayoutSettingsOpen}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      {searchExpandOpen && (
        <SearchExpand
          searchExpandOpen={searchExpandOpen}
          setSearchExpandOpen={setSearchExpandOpen}
        />
      )}
    </div>
  )
}

export default HubDashHeader
