import type {
  FindHubDashDataQuery,
  FindHubDashDataQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import HubDashHeader from 'src/components/HubDash/HubDashHeader/HubDashHeader'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindHubDashDataQuery {
    hubDashLayouts {
      id
      name
      layoutData
      userFilterType
      searchAccessType
      allowUserFilterOverride
      hubDashCards {
        id
        hubDashLayoutId
        name
        cardData
        cardSettings
        type
        source
        description
        position
        hubDashColorMap {
          id
          name
          description
          colorName
          icon
          type
        }
        linkedResources {
          resourceName
          resourceId
          resourceType
          linkMetaData
        }
      }
    }
  }
`

type CellProps = CellSuccessProps<
  FindHubDashDataQuery,
  FindHubDashDataQueryVariables
>

export type HubDashLayout = CellProps['hubDashLayouts'][0]
export type HubDashCard = CellProps['hubDashLayouts'][0]['hubDashCards'][0]
export type HubDashColorMap =
  CellProps['hubDashLayouts'][0]['hubDashCards'][0]['hubDashColorMap']

export type HubDashLinkedContent =
  CellProps['hubDashLayouts'][0]['hubDashCards'][0]['linkedResources'][0]

export enum LinkedResourceType {
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  PROCESS_MAP = 'PROCESS_MAP',
  LEARNER_COURSE = 'LEARNER_COURSE',
  HOME_LINK = 'HOME_LINK',
}

export const Loading = () => (
  <div>
    <LoadingSpinner size="small" />
  </div>
)

export const Empty = () => {
  return <HubDashHeader layouts={[]} />
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ hubDashLayouts }) => {
  return <HubDashHeader layouts={hubDashLayouts} />
}
